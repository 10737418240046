import { createPlanClick, pricingPlansInstallationStage } from '@wix/bi-logger-membership/v2';
import { MA_APP_IDS, withMembersArea } from '@wix/members-area-integration-kit';
import { CustomActionConfig, EditorPlatformApp } from '@wix/platform-editor-sdk';
import { EditorReadyFn, EditorScriptFlowAPI, FlowEditorSDK, FlowPlatformOptions } from '@wix/yoshi-flow-editor';
import { Logger } from '@wix/yoshi-flow-editor/build/esm/exports/bi';
import pricingPlans from '../.application.json';
import planList from './components/PlanList/.component.json';
import { EXPERIMENTS } from './constants/experiments';
import { createMemebersAreaInstallLogger } from './services/membersAreaInstallLogger';
import { PrivatePricingPlansApi } from './types/editor';
import { setPermissionsPage } from './utils/setPermissionsPage';

const AUTOPILOT_USER_ID = '6316f5bb-5858-4345-92ed-04566c1d7f54';
const ACTION_APP_INSTALLED = 'appInstalled';
const INSTALLATION_TYPE_AUTOPILOT = 'autopilot';
const INSTALLATION_TYPE_REGULAR = 'regular';

let appDefinitionId: string;
let editorSDK: FlowEditorSDK;
let flowAPI: EditorScriptFlowAPI;
let translate: EditorScriptFlowAPI['translations']['t'];
let biLogger: Logger;
let initialAppData: FlowPlatformOptions['initialAppData'];

const isAutopilotUser = async () => (await editorSDK.info.getUserInfo(appDefinitionId)).userId === AUTOPILOT_USER_ID;

const reportPricingPlansInstallationStage = async (stage: string) => {
  const isAutopilot = await isAutopilotUser();
  await biLogger.report(
    pricingPlansInstallationStage({
      appId: appDefinitionId,
      appInstanceId: await editorSDK.info.getAppInstanceId(appDefinitionId),
      biToken: await editorSDK.info.getMetaSiteId(appDefinitionId),
      installationType: isAutopilot ? INSTALLATION_TYPE_AUTOPILOT : INSTALLATION_TYPE_REGULAR,
      stage,
    }),
  );
};

const _editorReady: EditorReadyFn = async (sdk, appDefId, options, _flowAPI) => {
  appDefinitionId = appDefId;
  editorSDK = sdk;
  flowAPI = _flowAPI;
  translate = _flowAPI.translations.t;
  initialAppData = options.initialAppData;
  biLogger = options.essentials.biLoggerFactory().logger();

  // @todo: sarunas - IMHO this should be removed, cause no need each time set custom error page (or is it?)
  await trySetPermissionsPage();
};

const _handleAction: EditorPlatformApp['handleAction'] = async ({ type, payload }) => {
  if (
    type === ACTION_APP_INSTALLED &&
    (payload as Record<string, string>).appDefinitionId === pricingPlans.appDefinitionId
  ) {
    await reportPricingPlansInstallationStage(ACTION_APP_INSTALLED);
    await trySetPermissionsPage();
  }
};

const trySetPermissionsPage = async () => {
  try {
    await setPermissionsPage(editorSDK, appDefinitionId);
  } catch (e) {
    flowAPI.reportError(e);
  }
};

const platformApp = withMembersArea(
  {
    editorReady: _editorReady,
    handleAction: _handleAction,
  },
  {
    disableADI: false,
    membersAreaApps: [MA_APP_IDS.MY_SUBSCRIPTIONS],
    onEvent: createMemebersAreaInstallLogger({
      fedopsLogger: () => flowAPI.fedops,
      isAutopilotUser,
      reportPricingPlansInstallationStage,
    }),
  },
);

export const editorReady = platformApp.editorReady;
export const handleAction = platformApp.handleAction;

export const getAppManifest: EditorPlatformApp['getAppManifest'] = async () => {
  const buildCustomActions = () => {
    const result: CustomActionConfig[] = [];
    result.push({
      title: translate('manage-apps-panel.manage-purchases'),
      actionId: 'openManagePurchases',
      icon: 'appManager_settingsAction',
      type: 'dashboard',
    });
    result.push({
      title: translate('manage-apps-panel.customize-plans-page'),
      icon: 'appManager_pagesAction',
      actionId: 'openPricingPlansSettings',
      type: 'editorActions',
    });
    result.push({
      title: translate('manage-apps-panel.add-pricing-plans-elements'),
      icon: 'appManager_addElementsAction',
      actionId: 'addPricingPlansElements',
      type: 'editorActions',
    });
    result.push({
      title: translate('manage-apps-panel.manage-email-automations'),
      icon: 'email_icon',
      actionId: 'openMyAutomations',
      type: 'dashboard',
    });
    return result;
  };

  return {
    routers: undefined, // not in this article, not needed in a new manifest
    controllersStageData: {
      [planList.id]: {
        default: {
          displayName: translate('manifest.plan-list-widget.display-name'),
        },
      },
    },
    appDescriptor: {
      mainActions: [
        {
          title: translate('manage-apps-panel.manage-plans'),
          actionId: 'openManagePlans',
          icon: 'appManager_settingsAction',
        },
        {
          title: translate('manage-apps-panel.create-new-plan'),
          actionId: 'createNewPlan',
          icon: 'appManager_addElementsAction',
        },
      ],
      customActions: buildCustomActions(),
      defaultActions: {
        upgrade: {
          upgradeType: 'SITE_UPGRADE',
          upgradeText: translate('manage-apps-panel.upgrade-text'),
          upgradeLinkText: translate('manage-apps-panel.upgrade-link-text'),
        },
        learnMoreKB: 'b1d66914-9073-43d1-af55-f923aac621b0',
      },
    },
  };
};

export const onEvent: EditorPlatformApp['onEvent'] = async ({ eventType, eventPayload }) => {
  if (eventType === 'appActionClicked') {
    const { actionId } = eventPayload;
    if (actionId === 'openManagePlans') {
      await editorSDK.editor.openDashboardPanel(appDefinitionId, {
        url: '/membership/?referralInfo=manage_app_panel',
        closeOtherPanels: true,
      });
    } else if (actionId === 'createNewPlan') {
      await biLogger.report(
        createPlanClick({
          appInstanceId: await editorSDK.info.getAppInstanceId(appDefinitionId),
          biToken: await editorSDK.info.getMetaSiteId(appDefinitionId),
          formOrigin: 'null',
          referralInfo: 'manage_app_panel',
        }),
      );
      await editorSDK.editor.openDashboardPanel(appDefinitionId, {
        url: '/membership/setup?referralInfo=manage_app_panel',
        closeOtherPanels: true,
      });
    } else if (actionId === 'openManagePurchases') {
      await editorSDK.editor.openDashboardPanel(appDefinitionId, {
        url: '/subscriptions/?referralInfo=manage_app_panel',
        closeOtherPanels: true,
      });
    } else if (actionId === 'openPricingPlansSettings') {
      await privateApi.openSettings();
    } else if (actionId === 'addPricingPlansElements') {
      await privateApi.openAddons();
    } else if (actionId === 'openMyAutomations') {
      await editorSDK.editor.openDashboardPanel(appDefinitionId, {
        url: '/triggers/?referralInfo=manage_app_panel',
        closeOtherPanels: true,
      });
    }
  }
};

const privateApi: PrivatePricingPlansApi = {
  async openAddons() {
    const { openAddons } = await import('./utils/settings');
    await openAddons(
      editorSDK,
      translate,
      initialAppData.editorScriptUrl,
      flowAPI.experiments.enabled(EXPERIMENTS.NEW_ADDONS_DESIGN),
    );
  },
  async openSettings() {
    const { openPricingPlansSettings } = await import('./utils/settings');
    await openPricingPlansSettings(editorSDK, biLogger);
  },
};

export const exports: EditorPlatformApp['exports'] = () => {
  return {
    private: privateApi,
  };
};
